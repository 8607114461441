<template>
  <g>
    <g id="g123"
      ><g id="g125" clip-path="url(#clipPath129)"
        ><text
          transform="matrix(1,0,0,-1,1843,13617)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text133"
          ><tspan x="0" y="0" id="tspan131">1</tspan></text
        ></g
      ></g
    ><g id="g135"
      ><g id="g137" clip-path="url(#clipPath141)"
        ><text
          transform="matrix(1,0,0,-1,771,12605)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text145"
          ><tspan x="0" y="0" id="tspan143">A</tspan></text
        ><text
          transform="matrix(1,0,0,-1,4205,13617)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text149"
          ><tspan x="0" y="0" id="tspan147">2</tspan></text
        ><text
          transform="matrix(1,0,0,-1,6568,13618)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text153"
          ><tspan x="0" y="0" id="tspan151">3</tspan></text
        ><text
          transform="matrix(1,0,0,-1,8930,13617)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text157"
          ><tspan x="0" y="0" id="tspan155">4</tspan></text
        ><text
          transform="matrix(1,0,0,-1,11292,13619)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text161"
          ><tspan x="0" y="0" id="tspan159">5</tspan></text
        ><text
          transform="matrix(1,0,0,-1,13654,13618)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text165"
          ><tspan x="0" y="0" id="tspan163">6</tspan></text
        ><text
          transform="matrix(1,0,0,-1,16016,13618)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text169"
          ><tspan x="0" y="0" id="tspan167">7</tspan></text
        ><text
          transform="matrix(1,0,0,-1,18378,13618)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text173"
          ><tspan x="0" y="0" id="tspan171">8</tspan></text
        ><text
          transform="matrix(1,0,0,-1,1843,295)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text177"
          ><tspan x="0" y="0" id="tspan175">1</tspan></text
        ><text
          transform="matrix(1,0,0,-1,4205,295)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text181"
          ><tspan x="0" y="0" id="tspan179">2</tspan></text
        ><text
          transform="matrix(1,0,0,-1,6568,296)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text185"
          ><tspan x="0" y="0" id="tspan183">3</tspan></text
        ><text
          transform="matrix(1,0,0,-1,8930,295)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text189"
          ><tspan x="0" y="0" id="tspan187">4</tspan></text
        ><text
          transform="matrix(1,0,0,-1,11292,297)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text193"
          ><tspan x="0" y="0" id="tspan191">5</tspan></text
        ><text
          transform="matrix(1,0,0,-1,13654,296)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text197"
          ><tspan x="0" y="0" id="tspan195">6</tspan></text
        ><text
          transform="matrix(1,0,0,-1,16016,296)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text201"
          ><tspan x="0" y="0" id="tspan199">7</tspan></text
        ><text
          transform="matrix(1,0,0,-1,18378,296)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text205"
          ><tspan x="0" y="0" id="tspan203">8</tspan></text
        ><text
          transform="matrix(1,0,0,-1,771,10346)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text209"
          ><tspan x="0" y="0" id="tspan207">B</tspan></text
        ><text
          transform="matrix(1,0,0,-1,767,8086)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text213"
          ><tspan x="0" y="0" id="tspan211">C</tspan></text
        ><text
          transform="matrix(1,0,0,-1,767,5826)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text217"
          ><tspan x="0" y="0" id="tspan215">D</tspan></text
        ><text
          transform="matrix(1,0,0,-1,771,3566)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text221"
          ><tspan x="0" y="0" id="tspan219">E</tspan></text
        ><text
          transform="matrix(1,0,0,-1,776,1307)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text225"
          ><tspan x="0" y="0" id="tspan223">F</tspan></text
        ><text
          transform="matrix(1,0,0,-1,19432,12605)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text229"
          ><tspan x="0" y="0" id="tspan227">A</tspan></text
        ><text
          transform="matrix(1,0,0,-1,19432,10346)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text233"
          ><tspan x="0" y="0" id="tspan231">B</tspan></text
        ><text
          transform="matrix(1,0,0,-1,19428,8086)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text237"
          ><tspan x="0" y="0" id="tspan235">C</tspan></text
        ><text
          transform="matrix(1,0,0,-1,19428,5826)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text241"
          ><tspan x="0" y="0" id="tspan239">D</tspan></text
        ><text
          transform="matrix(1,0,0,-1,19432,3566)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text245"
          ><tspan x="0" y="0" id="tspan243">E</tspan></text
        ><text
          transform="matrix(1,0,0,-1,19437,1307)"
          style="font-variant:normal;font-weight:normal;font-size:164.994px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text249"
          ><tspan x="0" y="0" id="tspan247">F</tspan></text
        ><path
          d="m 10865,472 h 8504"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path251"/><path
          d="m 16487,944 h 2882"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path253"/><path
          d="m 13605,1747 h 5764"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path255"/><path
          d="m 10865,2220 h 8504"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path257"/><path
          d="M 19369,472 V 2692"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path259"/><path
          d="M 18660,472 V 944"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path261"/><path
          d="M 16960,472 V 944"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path263"/><path
          d="M 16487,472 V 2692"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path265"/><path
          d="M 13605,472 V 2692"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path267"/><path
          d="m 11999,2220 v 472"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path269"/><path
          d="M 19369,2692 H 10865 V 472"
          style="fill:none;stroke:#000000;stroke-width:17;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path271"/>
        <text
          transform="matrix(1,0,0,-1,10913,2560)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text275"
          ><tspan
            x="0 85.769989 151.82001 217.87003 250.89503"
            y="0"
            sodipodi:role="line"
            id="tspan273"
            >Dept.</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,12046,2560)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text279"
          ><tspan
            x="0 72.583748 138.63376 198.03127 264.0813 330.13132 356.50378 415.90131 481.95132 508.32379 541.34882 580.90753 646.95758 679.9826 746.03259 785.59131 851.64136 917.69135 977.08887"
            y="0"
            sodipodi:role="line"
            id="tspan277"
            >Technical reference</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13653,2560)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text283"
          ><tspan
            x="0 85.769989 125.32873 191.37874 257.42877 290.45377 356.50378 422.5538 455.57883 521.62885"
            y="0"
            sodipodi:role="line"
            id="tspan281"
            >Created by</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,16534,2560)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text287"
          ><tspan
            x="0 79.236267 145.28629 211.3363 250.89503 316.94507 376.34256 442.39258 508.4426 541.46759 607.51764"
            y="0"
            sodipodi:role="line"
            id="tspan285"
            >Approved by</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13653,2088)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text291"
          ><tspan
            x="0 85.769989 151.82001 211.21751 277.26752 376.22375 442.27377 508.32379 541.34882 574.37384 607.39886 666.79633 732.84637"
            y="0"
            sodipodi:role="line"
            id="tspan289"
            >Document type</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,16534,2088)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text295"
          ><tspan
            x="0 85.769989 151.82001 211.21751 277.26752 376.22375 442.27377 508.32379 541.34882 574.37384 633.7713 666.79633 732.84637 765.87134 831.92139"
            y="0"
            sodipodi:role="line"
            id="tspan293"
            >Document status</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13653,1615)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text299"
          ><tspan
            x="0 72.583748 98.956238 131.98125 158.35373"
            y="0"
            sodipodi:role="line"
            id="tspan297"
            >Title</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,16534,1615)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text303"
          ><tspan
            x="0 85.769989 197.91248 290.33499 323.35999 409.12997 475.17999"
            y="0"
            sodipodi:role="line"
            id="tspan301"
            >DWG No.</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,16534,812)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text307"
          ><tspan
            x="0 85.769989 151.82001 211.21751"
            y="0"
            sodipodi:role="line"
            id="tspan305"
            >Rev.</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,17007,812)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text311"
          ><tspan
            x="0 85.769989 151.82001 184.84502 250.89503 283.92004 349.97006 382.99509 416.02008 442.39258 501.79007 561.18756 627.23761"
            y="0"
            sodipodi:role="line"
            id="tspan309"
            >Date of issue</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,18708,812)"
          style="font-variant:normal;font-weight:normal;font-size:118.795px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text315"
          ><tspan
            x="0 79.236267 145.28629 211.3363 277.38632"
            y="0"
            sodipodi:role="line"
            id="tspan313"
            >Sheet</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,16595,536)"
          style="font-variant:normal;font-weight:normal;font-size:230.991px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text319"
          ><tspan x="0 128.431" y="0" sodipodi:role="line" id="tspan317"
            >00</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13653,543)"
          style="font-variant:normal;font-weight:normal;font-size:150.988px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text323"
          ><tspan
            x="0"
            y="0"
            sodipodi:role="line"
            id="tspan321"
            >{{ Object.values(project.fixture_design_details)[0].full_part_number }}</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,17003,536)"
          style="font-variant:normal;font-weight:normal;font-size:230.988px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text323"
          ><tspan
            x="0"
            y="0"
            sodipodi:role="line"
            id="tspan321"
            >{{ new Date(project.modified).toLocaleDateString() }}</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13653,1818)"
          style="font-variant:normal;font-weight:normal;font-size:230.991px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text327"
          ><tspan
            x="0 154.071 269.5665 385.06201 513.49298 705.90851 834.33948 885.61951 1001.115 1065.3304 1232.106 1309.026 1437.457 1604.2325 1655.5125 1783.9435"
            y="0"
            sodipodi:role="line"
            id="tspan325"
            >Assembly Drawing</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,18854,536)"
          style="font-variant:normal;font-weight:normal;font-size:230.991px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text331"
          ><tspan
            x="0 128.431 192.6465"
            y="0"
            sodipodi:role="line"
            id="tspan329"
            >1/1</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13653,897)"
          style="font-variant:normal;font-weight:normal;font-size:150.988px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text335"
          ><tspan
            x="0"
            y="0"
            sodipodi:role="line"
            id="tspan333"
            >{{ `${project.user_details.username} ${project.project_name}`}}</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,16919,1169)"
          style="font-variant:normal;font-weight:normal;font-size:230.988px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text339"
          ><tspan
            x="0 183.47333 366.94666 550.41998 660.30597 880.40796 1100.51 1320.6119 1540.714 1650.6 1834.0734"
            y="0"
            sodipodi:role="line"
            id="tspan337"
            >.</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13653,1251)"
          style="font-variant:normal;font-weight:normal;font-size:150.988px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text343"
          ><tspan
            x="0"
            y="0"
            sodipodi:role="line"
            id="tspan341"
            >{{ `${sideToggle == 1 ? 'Top' : 'Bottom'} ${btnToggle == 1 ? 'Probe' : 'Pressure'} Plate View` }}</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,12046,2291)"
          style="font-variant:normal;font-weight:normal;font-size:150.991px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text347"
          ><tspan
            x="0"
            y="0"
            sodipodi:role="line"
            id="tspan345"
            >{{ Object.values(project.fixture_design_details)[0].full_part_number }}</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,13646,2291)"
          style="font-variant:normal;font-weight:normal;font-size:150.991px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text347"
          ><tspan
            x="0"
            y="0"
            sodipodi:role="line"
            id="tspan345"
            >{{ `${project.user_details.username} - ${project.user_details.email}` }}</tspan
          ></text
        ><text
          transform="matrix(1,0,0,-1,10913,2291)"
          style="font-variant:normal;font-weight:normal;font-size:230.991px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="text351"
          ><tspan
            x="0 154.071 320.8465"
            y="0"
            sodipodi:role="line"
            id="tspan349"
            >.</tspan
          ></text
        ><g id="g353"><g id="g355" clip-path="url(#clipPath359)"/></g
        ><g id="g361"
          ><g id="g363" clip-path="url(#clipPath367)"
            ><g id="g369" transform="matrix(1919,0,0,1502,11291,566)"
              ><image
                width="1"
                height="1"
                style="image-rendering:optimizeSpeed"
                preserveAspectRatio="none"
                transform="matrix(1,0,0,-1,0,1)"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH4AAABjCAYAAABda7TNAAAABHNCSVQICAgIfAhkiAAAAP1JREFUeJzt0UERACAMwLCBf88gY48mCnrXMzNvyLnbAewwPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPsr4KOOjjI8yPuoD3NcBxfLd7joAAAAASUVORK5CYII="
                mask="url(#mask371)"
                id="image375"/></g></g></g></g
    ></g>
  </g>
</template>
<script>
export default {
  name: "LabelGroup",
  props: {
    project: {
      type: Object,
      default: null,
    },
    btnToggle: {
      type: Number,
      default: 1,
    },
    sideToggle: {
      type: Number,
      default: 1,
    },
  },
};
</script>
